import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({

  '& .doubleImage': {
    '& .multiFilteredCardImageWrapper': {
      [theme.breakpoints.up('lg')]: {
        height: '222px',

      },
    },
  },
  listItemContainer: {
    height: '100%',
  },
}));

export default useStyles;
